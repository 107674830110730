import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { t } from 'translations';

import { PaymentMethodForm } from 'apps-common/components/PaymentMethodForm';
import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';
import { useGetPaymentGateways } from 'apps-common/hooks/useGetPaymentGateways';
import { Currency, FlowType } from 'apps-common/types';
import { track } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { Flags, useFlag } from 'apps-common/utils/featureFlags';
import { useCreateSikaPaymentMethod } from 'apps-common/utils/hsa';
import { logger } from 'apps-common/utils/logger';
import { renderPaymentErrorMessage } from 'apps-common/utils/paymentError';
import { onPaypalStart } from 'apps-common/utils/paypal';
import { ErrorBanner, Header, Loader } from 'ui';
import { MainContainer } from 'ui/styles/containers';

import { routes } from '../routes';
import { useStore } from '../store';
import { getSignupBannerText } from '../utils/helpers';

const APP_URL: string = import.meta.env.VITE_URL;
const PAYPAL_COMPONENT_ID = 'paypal-button';
const HSA_COMPONENT_ID = 'hsa-button';
const HSA_MERCHANT_ID: string = import.meta.env.VITE_SIKA_MERCHANT_ID;
const PAYPAL_MERCHANT_ID_US: string = import.meta.env.VITE_PAYPAL_MERCHANT_ID_US;
const PAYPAL_MERCHANT_ID_ROW: string = import.meta.env.VITE_PAYPAL_MERCHANT_ID_ROW;

const usePrepoulateAddress = () => {
  const billingAddress = useStore((state) => state.userAddressForm?.billingAddress);
  const isoAlpha3Code = useStore((state) => state.billingCountryIsoAlpha3Code);

  return {
    creditCardCountry: isoAlpha3Code,
    creditCardState: billingAddress?.state,
    creditCardAddress1: billingAddress?.address1,
    creditCardAddress2: billingAddress?.address2,
    creditCardCity: billingAddress?.city,
    creditCardPostalCode: billingAddress?.postalCode,
  };
};

const useSaveGatewaysToStore = (currency: Currency) => {
  const country = useStore((state) => state.userAddressForm?.shippingAddress.country)!;
  const setGateways = useStore((state) => state.setGateways);

  const { data: gatewayData, error } = useGetPaymentGateways(country, currency);
  useEffect(() => {
    if (gatewayData) {
      setGateways(gatewayData);
    }
  }, [gatewayData, setGateways]);

  if (error) {
    logger.error('Fetching payment gateways failed', {
      country,
      currency,
      gatewayData,
      error,
    });
    throwError('signupUnknown', error);
  }
};

export const SignUpPaymentMethod = () => {
  const navigate = useNavigate();
  const hsaFlag = useFlag(Flags.HSA);
  const { isFetching: isFetchingOffers, data } = useGetMembershipOfferings();

  const shippingAddress = useStore((state) => state.userAddressForm?.shippingAddress);
  const signupInfo = useStore((state) => state.signupInfo);
  const ratePlan = useStore((state) => state.selectedRatePlan);
  const accountId = useStore((state) => state.accountId);
  const email = useStore((state) => state.email);
  const clearPaymentError = useStore((state) => state.clearPaymentError);
  const setPaymentError = useStore((state) => state.setPaymentError);
  const paymentError = useStore((state) => state.paymentError);

  const previousPage = routes.product;
  const steps = { current: 3, total: 3 };
  const populatedFields = usePrepoulateAddress();

  const prepaidMonths = data?.prepaidMonths ?? 0;
  const isPrepaid = prepaidMonths > 0;
  const SUBTITLE = isPrepaid ? t('signup_payment_page_prepaid_subtitle') : t('signup_payment_page_default_subtitle');
  const BANNER_TEXT = getSignupBannerText(isPrepaid, prepaidMonths);
  const returnUrl = new URL(routes.paypalCallback, APP_URL).href;
  const cancelUrl = new URL(routes.paymentMethod, APP_URL).href;

  if (!ratePlan) {
    throwError('noProductFound', `product not found for country: ${shippingAddress?.country}`);
  }
  const { currency } = ratePlan;

  const isUnitedStates = shippingAddress?.country === 'US';
  const paypalMerchantId: string = isUnitedStates ? PAYPAL_MERCHANT_ID_US : PAYPAL_MERCHANT_ID_ROW;

  useSaveGatewaysToStore(currency as Currency);
  const {
    hostedPageId,
    creditCardGatewayName: ccGatewayName,
    paypalGatewayName: paypalGatewayName,
  } = useStore((state) => state.gateways);

  const { mutate: createSikaPaymentMethod, error: hsaCreationError } = useCreateSikaPaymentMethod();
  if (hsaCreationError) {
    setPaymentError(hsaCreationError);
  }

  return (
    <>
      <Header
        appType="signup"
        pageType="create"
        title={t('membership_signup_payment_method_title')}
        subTitle={SUBTITLE}
        bannerText={BANNER_TEXT}
        onBackClick={() => navigate(previousPage)}
        steps={steps}
        testId="signup-payment-method-header"
        ringSerialStatus={signupInfo.ringSerialStatus}
      />
      <MainContainer>
        {isFetchingOffers && <Loader />}
        {!isFetchingOffers && paymentError && (
          <ErrorBanner marginBottom="15px">{renderPaymentErrorMessage(paymentError)}</ErrorBanner>
        )}
        {!isFetchingOffers && (
          <PaymentMethodForm
            flowType={FlowType.normal}
            creditCard={{
              pageId: hostedPageId,
              gatewayName: ccGatewayName,
              prepopulatedFields: populatedFields,
              onSubmit: () => {
                clearPaymentError();
                track({
                  event: 'CTA Clicked',
                  payload: { cta: 'save_exit', action: 'onboarding_next_step' },
                });
              },
              clientErrorCallback: (zClientError) => {
                const { errorCode: code, errorMessage: message, ...rest } = zClientError;
                setPaymentError({ ...rest, message, code: code.toString() });
                logger.warn('CreditCardForm client error', {
                  error: zClientError,
                });
              },
            }}
            paypal={{
              gatewayName: paypalGatewayName,
              componentId: PAYPAL_COMPONENT_ID,
              merchantId: paypalMerchantId,
              onSubmit: () => {
                clearPaymentError();
                void onPaypalStart({
                  returnUrl,
                  cancelUrl,
                  isUnitedStates,
                  currency,
                });
              },
            }}
            hsa={{
              enabled: hsaFlag && isUnitedStates,
              accountId,
              email,
              componentId: HSA_COMPONENT_ID,
              merchantId: HSA_MERCHANT_ID,
              onCancel: () => {
                logger.info('User cancelled HSA payment');
                return;
              },
              onError: (error) => {
                logger.error('HSA payment method failed', { error });
                return;
              },
              onSubmit: (_event) => {
                clearPaymentError();
                logger.info('Submitting HSA/FSA payment');
                track({
                  event: 'CTA Clicked',
                  payload: { cta: 'save_exit', action: 'onboarding_next_step' },
                });
                return;
              },
              onSuccess: ({ card, customer }) => {
                createSikaPaymentMethod(
                  {
                    token: card.id,
                    cardLastFour: card.last_four,
                    cardType: card.brand,
                    customerId: customer.id,
                    expirationMonth: card.expiration_month,
                    expirationYear: card.expiration_year,
                  },
                  {
                    onSuccess: (response) => {
                      logger.info('HSA payment method created, continuing to callback page', response);
                      navigate(
                        `${routes.hsaCallback}?${new URLSearchParams({ paymentMethodId: response.paymentMethodId }).toString()}`,
                      );
                    },
                  },
                );
                return;
              },
            }}
          />
        )}
      </MainContainer>
    </>
  );
};
