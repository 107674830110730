import './styles/normalize.css';

import * as React from 'react';

import { StatsigClient } from '@statsig/js-client';
import { StatsigProvider } from '@statsig/react-bindings';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { LocaleProvider } from 'translations';

import { ConsentManager } from 'apps-common/components/ConsentManager';
import { AuthProvider } from 'apps-common/context/auth';
import { resetAllSlices } from 'apps-common/store';
import { useInitAnalytics } from 'apps-common/utils/analytics';
import { Flags, useFlag } from 'apps-common/utils/featureFlags';
import { initSentry } from 'apps-common/utils/sentry';
import { theme } from 'ui/themes/signup';

import { Router } from './router';
import { GlobalStyles } from './styles/global';

const ENV = import.meta.env.MODE;
const SS_CLIENT_SDK_KEY: string = import.meta.env.VITE_SS_CLIENT_SDK_KEY;

const queryClient = new QueryClient();

const statsigClient = new StatsigClient(SS_CLIENT_SDK_KEY, {}, { environment: { tier: ENV } });
await statsigClient.initializeAsync();

const Main = () => {
  useInitAnalytics('signup');
  const moiFlag = useFlag(Flags.MOI_AUTH);
  return (
    <React.StrictMode>
      <StatsigProvider client={statsigClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <QueryClientProvider client={queryClient}>
            <AuthProvider
              onLogout={() => {
                resetAllSlices();
                if (moiFlag) {
                  window.location.replace(import.meta.env.VITE_CURITY_URL + '/authn/authentication/logout');
                }
              }}
            >
              <LocaleProvider>
                <Router />
                <ConsentManager />
              </LocaleProvider>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ThemeProvider>
      </StatsigProvider>
    </React.StrictMode>
  );
};

initSentry();

ReactDOM.createRoot(document.getElementById('root')!).render(<Main />);
